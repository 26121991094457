@import "variables", "mixins";


//
// Text
//

.text-small-caps {
    font-variant: small-caps;
}


// Element Visibility based on Theme

.light-theme {
    .hidden-light,
    .visible-dark {
        display: none;
    }
}

.dark-theme {
    .hidden-dark,
    .visible-light {
        display: none;
    }
}