@import "variables", "mixins";

.page-archive .section-days {
    .day {
        position: relative;
        display: block;
        margin-bottom: 2px;
        height: 45vw;
        overflow: hidden;

        @include hover {
            .day-bg-overlay {
                opacity: 0.6;
            }

            .day-content-left,
            .day-content-right {
                .day-content-inner {
                    transform: translateX(0);
                }

                .day-hr {
                    transform: scaleX(1);
                }
            }

            .day-title {
                opacity: 1;
                transform: translateX(0);
            }

            .day-directors {
                opacity: 1;
            }
        }
    }

    .day-bg-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .day-bg-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #000;
        opacity: 0;
        transition: opacity 0.4s;
    }

    .day-content,
    .day-directors {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 5vw calc(5vw - 0.5rem);
    }

    .day-content {
        display: inline-block;
        font-size: 0;
        color: $gray-lightest;
    }

    .day-content-left {
        display: none;

        .day-content-inner {
            // transform: translateX(calc(-100% + 35px));
        }

        .day-hr {
            transform-origin: left;
            transform: scaleX(0);
        }

        .day-title {
            transform: translateX(-40px);
        }
    }

    .day-content-right {
        text-align: right;

        .day-content-inner {
            // transform: translateX(calc(100% - 35px));
        }

        .day-hr {
            transform-origin: right;
            transform: scaleX(0);
        }

        .day-title {
            transform: translateX(40px);
        }
    }

    .day-content-inner {
        display: inline-block;
        white-space: nowrap;
        transition: transform 0.4s $ease-out-quart;
    }

    .day-content-inner > * {
        display: inline-block;
        vertical-align: middle;
    }

    .day-title,
    .day-number {
        font-size: $font-size-lg;
    }

    .day-directors {
        display: none;
        opacity: 0;
        transition: opacity 0.4s;
    }

    .day-director + .day-director {
        &:before {
            content: ', ';
        }
    }

    .day-hr {
        display: none;
        margin: 0 20px;
        width: 80px;
        border-color: $gray-lightest;
        transition: transform 0.4s $ease-out-quint;
    }

    .day-title {
        display: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        opacity: 0;
        transition: opacity 0.4s linear, transform 0.4s $ease-out-quart;

        $padding-width: 100px;
        $number-width: 35px;
        $hr-width: 120px;
        $offset-width: $padding-width + $number-width + $hr-width;

        @include media-breakpoint-up(sm) {
            max-width: calc((100vw - 120px) - #{$offset-width});
        }

        @include media-breakpoint-up(md) {
            max-width: calc((100vw - 160px) / 2 - #{$offset-width});
        }

        @media (min-width: $container-fluid-max-width) {
            max-width: calc(1340px / 2 - #{$offset-width});
        }
    }

    .day-directors {
        transform: translateY(15px);
        color: $gray-lightest;
        text-align: right;

        em {
            color: $gray;
        }
    }

    @include media-breakpoint-up(md) {
        .day {
            height: 28.5vw;
            max-height: 425px;
        }
    }

    @include media-breakpoint-up(lg) {
        .day-content,
        .day-directors {
            padding: 0 50px calc(50px - 0.5rem);
        }

        .day-directors {
            display: block;
        }

        .day-hr,
        .day-title {
            display: inline-block;
        }

        .col-day:nth-child(even) {
            .day-content-right {
                display: none;
            }

            .day-content-left {
                display: inline-block;
            }

            .day-directors {
                text-align: left;
            }
        }
    }
}