@import "variables", "mixins";

// Wrapper and base class
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
    position: absolute;
    left: 0;
    right: 0;
    z-index: $zindex-navbar-fixed;
    line-height: $navbar-line-height;

    > .container {
        position: relative;
    }

    .text-wrapper,
    .date-wrapper {
        margin: 0 auto;
        text-align: center;
        font-size: 0;
    }

    .text-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        opacity: 0;
        display: none;
    }

    .text,
    .prev,
    .next,
    .date {
        vertical-align: middle;
    }

    .text,
    .date {
        display: inline-block;
        margin: 0 15px;
        height: 80px;
        font-size: 2.5rem;
        line-height: inherit;
        white-space: nowrap;
        overflow: hidden;
    }

    .text {
        margin: 0;
        padding: 0 10px;
        width: calc(100% - 40px);
        text-overflow: ellipsis;
    }

    .prev,
    .next {
        display: inline-block;
        padding: 0 10px;
        font-size: 1rem;
        opacity: 1;
        transition: opacity 0.2s;

        &[disabled] {
            opacity: 0.2;
            cursor: default;
        }
    }

    .mobile-link {
        position: relative;
        width: 30px;
        z-index: 1;

        &:first-child {
            float: left;
            text-align: left;
        }

        &:last-child {
            float: right;
            text-align: right;
        }
    }

    @include media-breakpoint-up(sm) {
        .mobile-link {
            margin: 0 10px;
        }
    }

    @include media-breakpoint-up(md) {
        .text,
        .date {
            margin: 0 30px;
            font-size: $font-size-h1;
        }
    }
}

.navbar-inner {
    position: relative;
    height: $navbar-line-height;
}

.navbar-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}


//
// Brand/project name
//

.navbar-brand {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    margin-left: -8px;
    padding: 0;
    height: $navbar-line-height;
    width: 120px;
    overflow: hidden;
    z-index: 1;
}


// Navigation
//
// Custom navbar navigation built on the base `.nav` styles.

.navbar-nav {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.5rem;
    z-index: 1;

    .nav-item + .nav-item {
        margin-left: 0;
    }

    .nav-link {
        padding: 0 0.5rem;
        font-size: $font-size-xs;
    }
}


// Small Navbar
//
// Used when scrolling

.navbar-sm {
    // .date-wrapper {
    //     transform: scale(0.7);
    // }

    // .date {
    //     transform: scale(0.8);
    // }
}


// Fixed Navbar

.navbar-fixed-top {
    position: absolute;

    @include media-breakpoint-up(md) {
        position: fixed;
    }
}


// Themes

.light-theme {
    .navbar {
        .prev,
        .next,
        .mobile-link {
            color: $body-color-light;

            @include hover {
                color: $body-color-light;
            }
        }
    }

    .navbar-brand {
        color: $navbar-active-color-light;

        @include hover-focus {
            color: $navbar-active-color-light;
        }
    }

    .navbar-nav {
        .nav-link {
            color: $navbar-color-light;

            @include hover-focus {
                color: $navbar-hover-color-light;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .navbar-bg {
            background-color: $body-bg-light;
        }
    }
}

.dark-theme {
    .navbar {
        .prev,
        .next,
        .mobile-link {
            color: $body-color-dark;

            @include hover {
                color: $body-color-dark;
            }
        }
    }

    .navbar-brand {
        color: $navbar-active-color-dark;

        @include hover-focus {
            color: $navbar-active-color-dark;
        }
    }

    .navbar-nav {
        .nav-link {
            color: $navbar-color-dark;

            @include hover-focus {
                color: $navbar-hover-color-dark;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .navbar-bg {
            background-color: $body-bg-dark;
        }
    }
}

