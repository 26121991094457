@import "variables", "mixins";

.btn-arrow {
    position: relative;

    .arrow {
        position: absolute;
        top: 50%;
        left: 100%;
        height: 10px;
        width: 33px;
        font-size: 0;
        transform: translateY(-50%);
        transition: transform 0.3s $ease-out-quart;

        &:before,
        &:after {
            display: block;
            position: absolute;
            content: '';
        }

        &:before {
            position: absolute;
            top: 50%;
            left: 0;
            width: 32px;
            border-top: solid 1px;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform 0.8s $ease-out-circ;
        }

        &:after {
            top: 50%;
            right: 0;
            height: 5px;
            width: 5px;
            border-top: solid 1px;
            border-right: solid 1px;
            opacity: 0;
            transform: translate3d(-10px, 0.5px, 0) rotate(45deg);
            transform-origin: top right;
            transition: opacity 0.5s, transform 0.5s $ease-out-quart;
        }
    }

    @include hover-focus {
        .arrow {
            &:before {
                transform: scaleX(1);
            }

            &:after {
                opacity: 1;
                transform: translate3d(0, 0.5px, 0) rotate(45deg);
            }
        }
    }
}


// Themes

.light-theme {
    .btn-arrow {
        .arrow {
            &:before,
            &:after {
                border-color: $btn-primary-color-light;
            }
        }
    }
}

.dark-theme {
    .btn-arrow {
        .arrow {
            &:before,
            &:after {
                border-color: $btn-primary-color-dark;
            }
        }
    }
}