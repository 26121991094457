@import "variables", "mixins";

.modal-subscribe {
    .modal-content {
        height: calc(100vh - 60px);
    }

    .modal-header {
        height: $font-size-xl * $line-height;
    }

    .modal-body {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        padding-bottom: 1px;
        transform: translate(-50%, -50%);
    }

    @include media-breakpoint-up(sm) {
        .modal-content {
            height: calc(100vh - 140px);
        }
    }
}