@import "variables", "mixins";

.modal-terms {
    .modal-title {
        font-size: $font-size-xl;
    }

    .modal-body {
        margin: 0 auto;
        max-width: 560px;
        text-align: center;
        font-size: 1.25rem;
        color: $gray;
    }

    h3 {
        display: inline-block;
        margin-top: 3.25rem;
        margin-bottom: 2.5rem;
        font-size: inherit;
        font-weight: bold;
        border-bottom: solid 2px;
    }

    h4 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: inherit;
        line-height: inherit;
        font-family: 'gt_pressura_mono_reg';
    }

    p {
        margin-bottom: 1.75rem;
    }

    strong {
        font-family: 'gt_pressura_mono_reg';
        font-weight: normal;
    }


    // Themes

    .light-theme & {
        .modal-body {
            color: $gray;
        }

        h3,
        h4,
        strong {
            color: $gray-dark;
        }

        h3 {
            border-bottom-color: fade-out($gray-dark, .5);
        }
    }

    .dark-theme & {
        .modal-body {
            color: $gray;
        }

        h3,
        h4,
        strong {
            color: $gray-lighter;
        }

        h3 {
            border-bottom-color: fade-out($gray-lighter, .5);
        }
    }
}