@import "variables", "mixins";

.modal-search {
    .modal-title {
        position: absolute;
        top: -40px;
        left: 0;
        width: 100%;

        text-align: center;
        font-size: $font-size-xs;
        line-height: 1.33;
        letter-spacing: 0.2em;

        span {
            display: inline-block;
            border-bottom: solid 1px;
        }
    }

    .search:focus::placeholder {
        opacity: 1;
    }

    .search-results {
        // display: none;
        margin-bottom: 80px;
    }

    .search-similar {
        // display: none;

        h2 {
            display: inline-block;
            margin-bottom: 75px;
            font-size: $font-size-xl;
            border-bottom: solid 1px $gray-darker;
        }
    }
}


// Themes

.light-theme {
    .modal-search {
        .modal-title {
            color: $body-color-light;

            span {
                border-color: $body-color-light;
            }
        }

        .search-similar {
            h2 {
                border-color: $body-color-light;
            }
        }
    }
}

.dark-theme {
    .modal-search {
        .modal-title {
            color: $body-color-dark;

            span {
                border-color: $body-color-dark;
            }
        }

        .search-similar {
            h2 {
                border-color: $body-color-dark;
            }
        }
    }
}