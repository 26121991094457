@import "variables", "mixins";

.page-director .section-links {
    margin-bottom: 80px;

    .btn-link-wrapper {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .btn-link {
        font-size: $font-size-base;
        line-height: 1.667;
    }


    // Themes

    .light-theme & {
        .btn-link {
            color: $body-color-light;

            @include hover {
                color: $body-color-light;
            }
        }
    }

    .dark-theme & {
        .btn-link {
            color: $body-color-dark;

            @include hover {
                color: $body-color-dark;
            }
        }
    }
}