@import "variables", "mixins";

//
// Base styles
//

.btn {
    position: relative;
    transition: color .2s;
}


// Submit buttons

.btn-submit {
    &[disabled] {
        cursor: default;
        pointer-events: none;
    }
}


// Themes

.light-theme {
    .btn {
        @include hover-focus {
            color: $link-hover-color-light;
        }
    }

    .btn-primary {
        color: $btn-primary-color-light;
    }

    .btn-submit {
        color: $btn-submit-color-light;

        @include hover-focus {
            color: $btn-submit-color-light;
        }

        &[disabled] {
            color: $btn-primary-color-light;
        }

        &.btn-arrow {
            .arrow:before,
            .arrow:after {
                border-color: $btn-submit-color-light;
            }
        }
    }
}

.dark-theme {
    .btn {
        @include hover-focus {
            color: $link-hover-color-dark;
        }
    }

    .btn-primary {
        color: $btn-primary-color-dark;
    }

    .btn-submit {
        color: $btn-submit-color-dark;

        @include hover-focus {
            color: $btn-submit-color-dark;
        }

        &[disabled] {
            color: $btn-primary-color-dark;
        }

        &.btn-arrow {
            .arrow:before,
            .arrow:after {
                border-color: $btn-submit-color-dark;
            }
        }
    }
}