@import "variables", "mixins";

.page-director {
    margin-top: 120px;
    margin-bottom: 60px;
    
    @include media-breakpoint-up(md) {
        margin-top: 180px;
        margin-bottom: 200px;
    }
}