// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.


// Table of Contents
//
// Colors
// Options
// Spacing
// Body
// Links
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components

// General variable structure
//
// Variable format should follow the `$component-modifier-state-property` order.


// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$gray-darker:               #0f0f0f;
$gray-dark:                 #333333;
$gray:                      #666666;
$gray-light:                #999999;
$gray-lighter:              #cccccc;
$gray-lightest:             #f7f7f7;

$grid-line-color-light:     #ebebeb;
$grid-line-color-dark:      #1a1a1a;


// Easings
//
// A range of easing functions from http://easings.net/

// Quad
$ease-in-quad:              cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-out-quad:             cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-out-quad:          cubic-bezier(0.455, 0.03, 0.515, 0.955);

// Cubic
$ease-in-cubic:             cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-out-cubic:            cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-out-cubic:         cubic-bezier(0.645, 0.045, 0.355, 1);

// Quart
$ease-in-quart:             cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-out-quart:            cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-in-out-quart:         cubic-bezier(0.77, 0, 0.175, 1);

// Quint
$ease-in-quint:             cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-out-quint:            cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-out-quint:         cubic-bezier(0.86, 0, 0.07, 1);

// Expo
$ease-in-expo:              cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-out-expo:             cubic-bezier(0.19, 1, 0.22, 1);
$ease-in-out-expo:          cubic-bezier(1, 0, 0, 1);

// Circ
$ease-in-circ:              cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-out-circ:             cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-in-out-circ:          cubic-bezier(0.785, 0.135, 0.15, 0.86);

// Back
$ease-in-back:              cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-back:             cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-back:          cubic-bezier(0.68, -0.55, 0.265, 1.55);


// Icons
// 
// Custom icon variables

$icomoon-font-path: "../fonts";

$icon-view: "\e900";
$icon-email: "\e901";
$icon-close: "\e902";
$icon-facebook: "\e903";
$icon-link: "\e904";
$icon-next: "\e905";
$icon-prev: "\e906";
$icon-search: "\e907";
$icon-twitter: "\e908";


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-flex:               false;
$enable-rounded:            false;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-hover-media-query:  true;
$enable-grid-classes:       true;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.

$spacer:                     1rem;
$spacer-x:                   $spacer;
$spacer-y:                   $spacer;
$spacers: (
  0: (
    x:   0,
    y:   0
  ),
  1: (
    x:   $spacer-x,
    y:   $spacer-y
  ),
  2: (
    x:   ($spacer-x * 1.5),
    y:   ($spacer-y * 1.5)
  ),
  3: (
    x:   ($spacer-x * 3),
    y:   ($spacer-y * 3)
  )
);
$border-width:               1px;


// Body
//
// Settings for the `<body>` element.

$body-bg-light:              $gray-lightest;
$body-bg-dark:               $gray-darker;
$body-color-light:           $gray-darker;
$body-color-dark:            $gray-lightest;


// Links
//
// Style anchor elements.

$link-color-light:           $gray-lighter;
$link-color-dark:            $gray-dark;
$link-decoration:            none;
$link-hover-color-light:     darken($link-color-light, 15%);
$link-hover-color-dark:      lighten($link-color-dark, 15%);
$link-hover-decoration:      none;


// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 544px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 335px,
  md: 670px,
  lg: 940px,
  xl: 1340px
);

$container-fluid-max-width: 1500px;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:               8;
$grid-gutter-width:          2px;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-pressura:       "gt_pressura_mono";

$font-family-sans-serif:     "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:          Georgia, "Times New Roman", Times, serif;
$font-family-monospace:      #{$font-family-pressura}, "Courier New", monospace;
$font-family-base:           $font-family-monospace;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root:             16px;
$font-size-root-xs:          12px;

$font-size-base:             1.75rem; // 28px
$font-size-xl:               2.75rem; // 44px
$font-size-lg:               2rem;    // 32px
$font-size-sm:               1rem;    // 16px
$font-size-xs:               .75rem;  // 12px

$font-size-h1:               $font-size-xl;
$font-size-h2:               $font-size-base;
$font-size-h3:               $font-size-base;
$font-size-h4:               $font-size-sm;
// $font-size-h4:               1.5rem;
// $font-size-h5:               1.25rem;
// $font-size-h6:               1rem;

// $display1-size:               6rem;
// $display2-size:               5.5rem;
// $display3-size:               4.5rem;
// $display4-size:               3.5rem;

// $display1-weight:             300;
// $display2-weight:             300;
// $display3-weight:             300;
// $display4-weight:             300;

$line-height:                1.5;
$line-height-sm:             1.5;
$line-height-lg:             1.5;

$headings-margin-bottom:     ($spacer / 2);
$headings-font-family:       inherit;
$headings-font-weight:       lighter;
$headings-line-height:       1.1;
$headings-color:             inherit;

// $lead-font-size:             1.25rem;
// $lead-font-weight:           300;

// $text-muted:                  $gray-light;

// $abbr-border-color:           $gray-light;

// $blockquote-small-color:      $gray-light;
// $blockquote-font-size:        ($font-size-base * 1.25);
// $blockquote-border-color:     $gray-lighter;

// $hr-border-color:             rgba(0,0,0,.1);
// $hr-border-width:             $border-width;

// $dt-font-weight:              bold;

// $nested-kbd-font-weight:      bold;

// $list-inline-padding:         5px;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-navbar:            1000;
$zindex-dropdown:          1000;
$zindex-popover:           1060;
$zindex-tooltip:           1070;
$zindex-navbar-fixed:      1030;
$zindex-navbar-sticky:     1030;
$zindex-loader:            1035;
$zindex-modal-bg:          1040;
$zindex-modal:             1050;


// Navbar

$navbar-padding-horizontal:         0;
$navbar-padding-vertical:           0;
$navbar-line-height:                80px;

$navbar-color-light:                $body-color-light;
$navbar-color-dark:                 $body-color-dark;
$navbar-hover-color-light:          $body-color-light;
$navbar-hover-color-dark:           $body-color-dark;
$navbar-active-color-light:         $body-color-light;
$navbar-active-color-dark:          $body-color-dark;
$navbar-disabled-color-light:       fade-out($body-color-light, 0.75);
$navbar-disabled-color-dark:        fade-out($body-color-dark, 0.75);


// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$btn-padding-x:                  1rem;
$btn-padding-y:                  0;
$btn-font-weight:                lighter;

$btn-primary-color-light:        $gray-lighter;
$btn-primary-color-dark:         $gray-dark;
$btn-primary-bg:                 transparent;
$btn-primary-border:             transparent;

$btn-submit-color-light:         $body-color-light;
$btn-submit-color-dark:          $body-color-dark;

$btn-padding-x-sm:               .75rem;
$btn-padding-y-sm:               0;

$btn-padding-x-lg:               1.25rem;
$btn-padding-y-lg:               0;


// Forms

$input-padding-x:                .75rem;
$input-padding-y:                0;

$input-bg:                       transparent;
$input-bg-disabled:              transparent;

$input-color-light:              $body-color-light;
$input-color-dark:               $body-color-dark;
$input-border-color-light:       $gray-lighter;
$input-border-color-dark:        $gray-dark;
$input-btn-border-width:         $border-width; // For form controls and buttons
$input-box-shadow:               none;

$input-border-focus-light:       $gray-darker;
$input-border-focus-dark:        $gray-lightest;
$input-box-shadow-focus:         none;

$input-color-placeholder-light:  $gray-lighter;
$input-color-placeholder-dark:   $gray-dark;

$input-padding-x-sm:             .75rem;
$input-padding-y-sm:             0;

$input-padding-x-lg:             1.25rem;
$input-padding-y-lg:             0;

$input-height:                   ($font-size-base * $line-height);
$input-height-lg:                ($font-size-lg * $line-height-lg);
$input-height-sm:                ($font-size-sm * $line-height-sm);

$form-group-margin-bottom:       2.75rem;


// Modals

// Padding applied to the modal body
$modal-inner-padding:            0;

$modal-title-padding:            0;
$modal-title-line-height:        $line-height;

$modal-content-bg:               transparent;
$modal-content-border-color:     transparent;
$modal-header-border-color:      transparent;
$modal-footer-border-color:      transparent;

$modal-lg:                       100%;
$modal-md:                       100%;
$modal-sm:                       100%;


// Cards

$card-spacer-x:            1.25rem;
$card-spacer-y:            0.75rem;
$card-border-width:        0px;
$card-border-color:        transparent;
$card-cap-bg:              transparent;
$card-bg:                  transparent;


@import "bootstrap/scss/variables";