@import "variables", "mixins";

//
// Textual form controls
//

.form-control {
    border: none;
    border-bottom: solid $input-btn-border-width;
    text-align: center;
}


//
// Floating labels
//

.form-group {
    position: relative;
}

.form-control {
    &::placeholder {
        transition: opacity 0.3s;
    }

    &:focus::placeholder {
        opacity: 0;
    }
}

label {
    position: absolute;
    top: 0;
    left: 50%;
    margin-bottom: 0;

    font-size: 0.875rem;

    z-index: -1;
    opacity: 0;
    transform: translateX(-50%) translateY(0%);
    transition: opacity 0.3s, transform 0.3s $ease-out-quart;

    .has-value &,
    .form-control:focus + & {
        opacity: 1;
        transform: translateX(-50%) translateY(-100%);
    }
}


// Themes

.light-theme {
    .form-control {
        border-color: $input-border-color-light;
        color: $input-color-light;

        &::placeholder {
            color: $input-color-placeholder-light;
        }

        &:focus {
            border-color: $input-border-focus-light;
        }
    }

    label {
        color: $input-color-placeholder-light;
    }
}

.dark-theme {
    .form-control {
        border-color: $input-border-color-dark;
        color: $input-color-dark;

        &::placeholder {
            color: $input-color-placeholder-dark;
        }

        &:focus {
            border-color: $input-border-focus-dark;
        }
    }

    label {
        color: $input-color-placeholder-dark;
    }
}