@import "variables", "mixins";

.link-hover {
    position: relative;
    display: inline-block;

    .text {
        color: transparent;
    }

    .text-hover {
        position: absolute;
        top: 0;
        width: 150%;
        white-space: nowrap;
        pointer-events: none;
        transition: letter-spacing 0.3s $ease-out-expo;
    }

    @include hover {
        .text-hover {
            letter-spacing: 0.15em;
        }
    }
}

.link-hover-left {
    .text-hover {
        left: 0;
        text-align: left;
    }
}

.link-hover-right {
    .text-hover {
        right: 0;
        text-align: right;
    }
}

.link-hover-center {
    .text-hover {
        left: 50%;
        text-align: center;
        transform: translateX(-50%);
    }
}