@import "variables", "mixins";

.page-tag .section-results {
    // Themes

    .light-theme & {
        .card-tags {
            a {
                color: $gray-lighter;
            }

            .v-link-active {
                color: $body-color-light;

                @include hover {
                    color: $body-color-light;
                }
            }
        }
    }

    .dark-theme & {
        .card-tags {
            a {
                color: $gray-dark;
            }

            .v-link-active {
                color: $body-color-dark;

                @include hover {
                    color: $body-color-dark;
                }
            }
        }
    }
}