@import "variables";

@font-face {
    font-family: 'good-moves';
    src:
        url('#{$icomoon-font-path}/good-moves.ttf?pn0vqt') format('truetype'),
        url('#{$icomoon-font-path}/good-moves.woff?pn0vqt') format('woff'),
        url('#{$icomoon-font-path}/good-moves.svg?pn0vqt#good-moves') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'good-moves' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: inherit;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-app-store:before {
  content: "\e90c";
}
.icon-instagram:before {
  content: "\e90a";
}
.icon-vimeo:before {
  content: "\e90b";
}
.icon-down:before {
  content: "\e909";
}
.icon-view:before {
  content: "\e900";
}
.icon-email:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e902";
}
.icon-facebook:before {
  content: "\e903";
}
.icon-link:before {
  content: "\e904";
}
.icon-next:before {
  content: "\e905";
}
.icon-prev:before {
  content: "\e906";
}
.icon-search:before {
  content: "\e907";
}
.icon-twitter:before {
  content: "\e908";
}

