@import "variables", "mixins";

.modal-about {
    .modal-content {
        height: calc(100vh - 60px);
    }

    .modal-header {
        height: $font-size-xl * $line-height;
    }

    .modal-body {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        padding-bottom: 1px;
        transform: translateY(-50%);
    }
    
    h4 {
        margin-bottom: 3rem;
    }

    p {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2.5rem;
        max-width: 770px;
        font-size: $font-size-lg;
    }

    .social-links {
        margin-bottom: 2rem;
    }

    .social-link {
        margin: 0 10px;
        display: inline-block;
    }

    .app-links {
        margin-bottom: 2rem;
    }

    .app-link.ios {
        margin-left: 10px;
        font-size: 1.5rem;
    }

    @include media-breakpoint-up(sm) {
        .modal-content {
            height: calc(100vh - 140px);
        }
    }

    // Themes

    .light-theme & {
        .app-link,
        .social-link {
            color: $body-color-light;

            @include hover-focus {
                color: $body-color-light;
            }
        }

        .curated-by {
            color: $gray-lighter;
        }

        .btn-breeder {
            color: $body-color-light;

            @include hover-focus {
                color: $body-color-light;
            }
        }
    }

    .dark-theme & {
        .app-link,
        .social-link {
            color: $body-color-dark;

            @include hover-focus {
                color: $body-color-dark;
            }
        }

        .curated-by {
            color: $gray-dark;
        }

        .btn-breeder {
            color: $body-color-dark;

            @include hover-focus {
                color: $body-color-dark;
            }
        }
    }
}