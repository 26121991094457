@import "variables", "mixins";

.grid {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    pointer-events: none;
}

.grid-item {
    height: 100vh;
    border-right: solid 1px;

    @include media-breakpoint-up(sm) {
        &:first-child {
            border-left: solid 1px;
        }
    }
}


// Themes

.light-theme {
    .grid-item {
        border-color: $grid-line-color-light;
    }
}

.dark-theme {
    .grid-item {
        border-color: $grid-line-color-dark;
    }
}