@import "variables", "mixins";

.logo {
    position: absolute;
    top: 0;
    left: 0;
}

.logo-hover-large,
.logo-hover-small {
    visibility: hidden;
}