@import "variables", "mixins";

.page-archive {
    margin-top: 120px;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
        margin-top: 290px;
        margin-bottom: 100px;
    }
}