@import "variables", "mixins";

.btn-share {
    .text {
        opacity: 1;
    }

    .share-links {
        position: absolute;
        top: -0.75em;
        left: 50%;
        padding-top: 0.75em;
        padding-bottom: 0.75em;
        overflow: hidden;
        transform: translateX(-50%);
    }

    .share-link {
        margin: 0 10px;
        display: inline-block;

        [class^="icon-"], [class*=" icon-"] {
            line-height: 1;
        }

        &.facebook {
            width: 1.25em;
            text-align: right;
        }
    }

    .icon-email {
        font-size: 85%;
    }

    @include media-breakpoint-up(md) {
        .share-link {
            opacity: 0.3;
            transition: opacity 0.2s;

            @include hover {
                opacity: 1;
            }
        }
    }


    // Themes

    .light-theme & {
        .share-link {
            color: $body-color-light;

            @include hover {
                color: $body-color-light;
            }
        }
    }

    .dark-theme & {
        .share-link {
            color: $body-color-dark;

            @include hover {
                color: $body-color-dark;
            }
        }
    }
}