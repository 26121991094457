@import "variables", "mixins";

.info {
    .section-about {
        margin-bottom: 160px;

        h4 {
            margin-bottom: 3rem;
        }

        p {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2.5rem;
            max-width: 770px;
            font-size: $font-size-lg;
        }

        .social-links {
            margin-bottom: 2rem;
            font-size: 1.25rem;
        }

        .social-link {
            margin: 0 10px;
            display: inline-block;
        }

        .app-links {
            margin-bottom: 2rem;
        }

        .app-link.ios {
            margin-left: 10px;
            font-size: 2rem;
        }
    }

    .section-scroll {
        margin-bottom: 120px;
    }

    @include media-breakpoint-up(md) {
        .section-about {
            margin-bottom: 400px;

            .social-links {
                font-size: inherit;
            }

            .app-link.ios {
                font-size: 1.5rem;
            }
        }

        .section-scroll {
            margin-bottom: 400px;
        }
    }

    // Themes

    .light-theme & {
        .section-about {
            .app-link,
            .social-link {
                color: $body-color-light;

                @include hover-focus {
                    color: $body-color-light;
                }
            }
        }
    }

    .dark-theme & {
        .section-about {
            .app-link,
            .social-link {
                color: $body-color-dark;

                @include hover-focus {
                    color: $body-color-dark;
                }
            }
        }
    }
}