@import "variables", "mixins";

.card {
    margin-bottom: 3.75rem;
    cursor: pointer;

    img {
        width: 100%;
    }
}

.card-block {
    padding: 2.5rem;
}

.card-title,
.card-directors {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.card-img-top {
    position: relative;
    height: 187px;
}

.card-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.card-directors {
    margin-bottom: $card-spacer-y;
    font-size: $font-size-xs;
    letter-spacing: 0.1em;
}

.card-director + .card-director {
    &:before {
        content: ', ';
    }
}

.card-tags {
    margin: 0;
    padding: 0;
    height: 15px;

    font-size: 0;
    text-transform: uppercase;
    list-style: none;

    li {
        display: inline-block;
        margin-right: 15px;
        font-size: 10px; //$font-size-xs;
        letter-spacing: 0.1em;
    }
}


// Themes

.light-theme {
    .card-title {
        color: $body-color-light;
    }

    .card-tags,
    .card-directors {
        a {
            color: $body-color-light;

            @include hover {
                color: $body-color-light;
            }
        }
    }

    .card-directors {
        em {
            color: $gray-light;
        }
    }
}

.dark-theme {
    .card-title {
        color: $body-color-dark;
    }

    .card-tags,
    .card-directors {
        a {
            color: $body-color-dark;

            @include hover {
                color: $body-color-dark;
            }
        }
    }

    .card-directors {
        em {
            color: $gray;
        }
    }
}