@font-face {
    font-family: 'gt_pressura_mono_reg';
    src: url('../fonts/gtpressuramono-regular.eot');
    src: url('../fonts/gtpressuramono-regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gtpressuramono-regular.woff') format('woff'),
         url('../fonts/gtpressuramono-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gt_pressura_mono_reg';
    src: url('../fonts/gtpressuramono-regular-italic.eot');
    src: url('../fonts/gtpressuramono-regular-italic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gtpressuramono-regular-italic.woff') format('woff'),
         url('../fonts/gtpressuramono-regular-italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'gt_pressura_mono';
    src: url('../fonts/gtpressuramono-light.eot');
    src: url('../fonts/gtpressuramono-light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gtpressuramono-light.woff') format('woff'),
         url('../fonts/gtpressuramono-light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gt_pressura_mono';
    src: url('../fonts/gtpressuramono-light-italic.eot');
    src: url('../fonts/gtpressuramono-light-italic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gtpressuramono-light-italic.woff') format('woff'),
         url('../fonts/gtpressuramono-light-italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'gt_pressura_mono';
    src: url('../fonts/gtpressuramono-bold.eot');
    src: url('../fonts/gtpressuramono-bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gtpressuramono-bold.woff') format('woff'),
         url('../fonts/gtpressuramono-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'gt_pressura_mono';
    src: url('../fonts/gtpressuramono-bold-italic.eot');
    src: url('../fonts/gtpressuramono-bold-italic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gtpressuramono-bold-italic.woff') format('woff'),
         url('../fonts/gtpressuramono-bold-italic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}