@import "variables", "mixins";

html,
body {
    width: 100%;
    font-size: $font-size-root-xs;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include media-breakpoint-up(sm) {
        font-size: $font-size-root;
    }
}

main {
    min-height: calc(100vh - 164px);
    overflow: hidden;
}

.page-loading {
    display: none;
}


// Themes

.light-theme {
    background-color: $body-bg-light;
    color: $body-color-light;
}

.dark-theme {
    background-color: $body-bg-dark;
    color: $body-color-dark;
}