@import "variables", "mixins";

.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $zindex-loader;
    pointer-events: none;
}

.loader-bar {
    margin: 0;
    border-top-width: 4px;
    transition: all 1s $ease-out-expo !important;


    // Themes

    .light-theme & {
        border-top-color: $body-color-light;
    }

    .dark-theme & {
        border-top-color: $body-color-dark;
    }
}