@import "variables", "mixins";

p {
    margin-bottom: 1.25rem;
}

small {
    font-size: 1rem;

    @include media-breakpoint-up(sm) {
        font-size: $font-size-xs;
    }
}

a, button {
    &:focus {
        outline: none;
    }
}

a {
    transition: color .2s;
}


// Themes

.light-theme {
    h4 {
        color: $gray-light;
    }

    a {
        color: $link-color-light;

        @include hover-focus {
            color: $link-hover-color-light;
        }

        &:active,
        &.active {
            &:hover,
            &:focus,
            &.focus {
                color: $link-hover-color-light;
            }
        }
    }
}

.dark-theme {
    h4 {
        color: $gray-dark;
    }

    a {
        color: $link-color-dark;

        @include hover-focus {
            color: $link-hover-color-dark;
        }

        &:active,
        &.active {
            &:hover,
            &:focus,
            &.focus {
                color: $link-hover-color-dark;
            }
        }
    }
}