@import "variables", "mixins";

.subscribe {
    text-align: center;

    h4 {
        margin-bottom: 3rem;
    }

    .thankyou-message {
        position: absolute;
        top: 50%;
        width: 100%;
        opacity: 0;
        z-index: -1;
    }
}