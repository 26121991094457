@import "variables", "mixins";

.footer-nav {
    margin-top: 40px;
    margin-bottom: 20px;

    .row-mobile {
        margin-bottom: 10px;

        > .col-xs-8 {
            display: flex;
            justify-content: space-between;
        }

        a {
            display: inline-block;
            margin: 0;
            line-height: 24px;
        }
    }

    @include media-breakpoint-up(sm) {
        .row-mobile {
            > .col-xs-8 {
                display: block;
            }

            a {
                margin: 0 15px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        margin-top: 70px;
        margin-bottom: 70px;

        .row-mobile a {
            margin: 0 20px;
            line-height: inherit;
        }
    }


    // Themes

    .light-theme & {
        color: $gray-lighter;

        .btn-breeder {
            color: $body-color-light;

            @include hover-focus {
                color: $body-color-light;
            }
        }
    }

    .dark-theme & {
        color: $gray-dark;

        .btn-breeder {
            color: $body-color-dark;

            @include hover-focus {
                color: $body-color-dark;
            }
        }
    }
}