@import "variables", "mixins";

.page-director .section-results {
    h4 {
        margin-bottom: 30px;
    }

    // Themes

    .light-theme & {
        .card-directors {
            a {
                color: $gray-lighter;
            }

            .v-link-active {
                color: $body-color-light;

                @include hover {
                    color: $body-color-light;
                }
            }
        }
    }

    .dark-theme & {
        .card-directors {
            a {
                color: $gray-dark;
            }

            .v-link-active {
                color: $body-color-dark;

                @include hover {
                    color: $body-color-dark;
                }
            }
        }
    }
}