@import "variables", "mixins";

.modal {
    transition: opacity 0.3s ease 0.1s !important;

    &.in {
        background-color: transparent !important;
    }
}

.modal-dialog {
    @include make-container();
    
    .modal.fade & {
        transform: none;
        transition: none;
    }

    .modal.in & {
        transform: none;
    }

    @include media-breakpoint-up(sm) {
        margin-top: 70px;
    }
}

.modal-header {
    position: relative;
    margin-bottom: 2.5rem;

    .modal.fade & {
        transform: scale(1.05);
        transition: transform 0.3s ease-out 0.1s;
    }

    .modal.in & {
        transform: scale(1);
    }

    .close {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -1.5em;
        margin-right: -1em;
        padding: 1em;

        font-size: $font-size-sm;
        text-shadow: none;
        opacity: 1;
        z-index: 1;

        @include hover {
            opacity: 1;
        }
    }

    .form-control {
        padding: 0 ($font-size-sm * 2);
        font-size: $font-size-xl;
        border-bottom: none;
    }

    @include media-breakpoint-up(md) {
        .close {
            right: 40px;
        }

        .form-control {
            margin: 0 auto;
            width: calc(100% - 80px);
        }
    }


    // Themes

    .light-theme & {
        .close {
            color: $body-color-light;
        }
    }

    .dark-theme & {
        .close {
            color: $body-color-dark;
        }
    }
}

// Title text within header
.modal-title {
    text-align: center;
}


// Hide elements on page when modal is open

.modal-open {
    position: fixed;

    @include media-breakpoint-up(md) {
        position: static;
    }
}

.navbar-inner {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.3s, transform 0.3s;

    .modal-open & {
        opacity: 0;
        transform: scale(0.95);
    }
}

main,
.footer-nav {
    opacity: 1;
    transition: opacity 0.3s;

    .modal-open & {
        opacity: 0;
    }
}