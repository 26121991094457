@import "variables", "mixins";

.icon-archive {
    position: relative;
    display: inline-block;
    height: 80px;
    width: 20px;

    div {
        position: absolute;
        top: 50%;
        width: 100%;
        transition: transform 0.3s $ease-out-expo;

        &:before,
        &:after {
            position: absolute;
            top: 50%;
            display: block;
            margin-top: -2px;
            height: 4px;
            width: 8px;
            transition: transform 0.3s $ease-out-expo;
            content: '';
        }

        &:before { transform: translateY(-100%); }
        &:after { transform: translateY(100%); }

        &:first-child {
            &:before,
            &:after {
                left: 0;
            }
        }

        &:last-child {
            &:before,
            &:after {
                right: 0;
            }
        }
    }

    &.active {
        div {
            &:before { transform: translateY(-50%); }
            &:after { transform: translateY(50%); }

            &:first-child { transform: translateX(2px); }
            &:last-child { transform: translateX(-2px); }
        }
    }


    // Themes

    .light-theme & {
        div {
            &:before,
            &:after {
                background-color: $body-color-light;
            }
        }
    }

    .dark-theme & {
        div {
            &:before,
            &:after {
                background-color: $body-color-dark;
            }
        }
    }
}