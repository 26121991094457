@import "variables", "mixins";

.video {
    .video-embed {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;
        height: 100vh;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .video-directors {
        font-size: $font-size-sm;
        letter-spacing: 0.1em;

        a {
            color: inherit;

            @include hover-focus {
                color: inherit;
            }
        }
    }

    .video-director + .video-director {
        &:before {
            content: ', ';
        }
    }

    .video-tags {
        margin: 0;
        padding: 0;
        margin-bottom: 20px;

        font-size: 0;
        text-transform: uppercase;
        list-style: none;

        li {
            display: inline-block;
            margin: 0 15px;
            font-size: 10px; //$font-size-xs;
            letter-spacing: 0.1em;
        }
    }


    // Themes

    .light-theme & {
        .video-directors {
            em {
                color: $gray-light;
            }
        }

        .video-tags {
            color: $gray-lighter;
        }

        .btn {
            color: $gray-light;
        }
    }

    .dark-theme & {
        .video-directors {
            em {
                color: $gray;
            }
        }

        .video-tags {
            color: $gray-dark;
        }

        .btn {
            color: $gray;
        }
    }
}