@import "bootstrap/scss/mixins";

/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container($gutter: $grid-gutter-width) {
    margin-left: auto;
    margin-right: auto;
    // padding-left:  ($gutter / 2);
    // padding-right: ($gutter / 2);
    padding-left: 30px;
    padding-right: 30px;
    max-width: $container-fluid-max-width !important;
    
    @if not $enable-flex {
        @include clearfix();
    }

    @include media-breakpoint-up(sm) {
        padding-left: 60px;
        padding-right: 60px;
    }

    @include media-breakpoint-up(md) {
        padding-left: 80px;
        padding-right: 80px;
    }
}


// Disable webkit style focus
@mixin tab-focus() {
    outline: 0;
}
